async function sendUserDataToServer(download, endpoint, username) {
    download.classList.add('is-loading');

    try {
        await fetch(endpoint, {
            method: 'GET',
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
            })
            .then((data) => {
                const blob = new Blob([data], { type: 'text/vcard;charset=utf-8' });

                download.href = URL.createObjectURL(blob);
                download.download = `${username}.vcf`;
                download.click();
            });
    } catch (exception) {
        throw new Error('Something went wrong while downloading the vcf file.');
    } finally {
        download.classList.remove('is-loading');
    }
}

/**
 * Manages the creation and download of vcf file (vcard)
 */
export function vcfDownload() {
    const vcfDownloads = document.querySelectorAll('[data-vcf-download]');

    if (vcfDownloads) {
        vcfDownloads.forEach((download) => {
            download.addEventListener('click', async (event) => {
                if (download.href.search('blob') === -1) {
                    event.preventDefault();
                    const username = download.dataset.vcfDownloadUsername,
                        endpoint = download.dataset.vcfDownloadEndpoint;

                    await sendUserDataToServer(download, endpoint, username);
                }
            });
        });
    }
}
