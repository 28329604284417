import $ from 'jquery';

import { Foundation } from 'foundation-sites/js/foundation.core.js';
import { GetYoDigits, rtl, transitionend } from 'foundation-sites/js/foundation.core.utils.js';
import { Box } from 'foundation-sites/js/foundation.util.box.js';
import { Keyboard } from 'foundation-sites/js/foundation.util.keyboard.js';
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery.js';
import { Motion, Move } from 'foundation-sites/js/foundation.util.motion.js';
import { Nest } from 'foundation-sites/js/foundation.util.nest.js';
import { Timer } from 'foundation-sites/js/foundation.util.timer.js';
import { Touch } from 'foundation-sites/js/foundation.util.touch.js';
import { Triggers } from 'foundation-sites/js/foundation.util.triggers.js';
import { Accordion } from 'foundation-sites/js/foundation.accordion.js';
import { Dropdown } from 'foundation-sites/js/foundation.dropdown.js';
import { OffCanvas } from 'foundation-sites/js/foundation.offcanvas.js';
import { Reveal } from 'foundation-sites/js/foundation.reveal.js';
import { SmoothScroll } from 'foundation-sites/js/foundation.smoothScroll.js';
import { Toggler } from 'foundation-sites/js/foundation.toggler.js';
import { toElements } from './utils/to-elements.js';
// import { onImagesLoaded } from 'foundation-sites/js/foundation.util.imageLoader.js';
// import { Abide } from 'foundation-sites/js/foundation.abide.js';
// import { AccordionMenu } from 'foundation-sites/js/foundation.accordionMenu.js';
// import { Drilldown } from 'foundation-sites/js/foundation.drilldown.js';
// import { DropdownMenu } from 'foundation-sites/js/foundation.dropdownMenu.js';
// import { Equalizer } from 'foundation-sites/js/foundation.equalizer.js';
// import { Interchange } from 'foundation-sites/js/foundation.interchange.js';
// import { Magellan } from 'foundation-sites/js/foundation.magellan.js';
// import { Orbit } from 'foundation-sites/js/foundation.orbit.js';
// import { ResponsiveMenu } from 'foundation-sites/js/foundation.responsiveMenu.js';
// import { ResponsiveToggle } from 'foundation-sites/js/foundation.responsiveToggle.js';
// import { Slider } from 'foundation-sites/js/foundation.slider.js';
// import { Sticky } from 'foundation-sites/js/foundation.sticky.js';
// import { Tabs } from 'foundation-sites/js/foundation.tabs.js';
// import { Tooltip } from 'foundation-sites/js/foundation.tooltip.js';
// import { ResponsiveAccordionTabs } from 'foundation-sites/js/foundation.responsiveAccordionTabs.js';

Foundation.addToJquery($);

Foundation.rtl = rtl;
Foundation.GetYoDigits = GetYoDigits;
Foundation.transitionend = transitionend;

Foundation.Box = Box;
Foundation.Keyboard = Keyboard;
Foundation.MediaQuery = MediaQuery;
Foundation.Motion = Motion;
Foundation.Move = Move;
Foundation.Nest = Nest;
Foundation.Timer = Timer;
// Foundation.onImagesLoaded = onImagesLoaded;

Touch.init($);
Triggers.init($, Foundation);

Foundation.plugin(Accordion, 'Accordion');
Foundation.plugin(Dropdown, 'Dropdown');
Foundation.plugin(OffCanvas, 'OffCanvas');
Foundation.plugin(Reveal, 'Reveal');
Foundation.plugin(SmoothScroll, 'SmoothScroll');
Foundation.plugin(Toggler, 'Toggler');

// Foundation.plugin(Abide, 'Abide');
// Foundation.plugin(AccordionMenu, 'AccordionMenu');
// Foundation.plugin(Drilldown, 'Drilldown');
// Foundation.plugin(DropdownMenu, 'DropdownMenu');
// Foundation.plugin(Equalizer, 'Equalizer');
// Foundation.plugin(Interchange, 'Interchange');
// Foundation.plugin(Magellan, 'Magellan');
// Foundation.plugin(Orbit, 'Orbit');
// Foundation.plugin(ResponsiveMenu, 'ResponsiveMenu');
// Foundation.plugin(ResponsiveToggle, 'ResponsiveToggle');
// Foundation.plugin(Slider, 'Slider');
// Foundation.plugin(Sticky, 'Sticky');
// Foundation.plugin(Tabs, 'Tabs');
// Foundation.plugin(Tooltip, 'Tooltip');
// Foundation.plugin(ResponsiveAccordionTabs, 'ResponsiveAccordionTabs');

export async function init() {
    const body = document.body,
        menuButtons = document.querySelectorAll('.menu-toggle');

    let offCanvasActive = 0,
        inCanvasOffCanvas;

    $(document)
        .foundation()
        .on('opened.zf.offCanvas', (event) => {
            let target = event.target;

            offCanvasActive++;

            body.classList.add('is-off-canvas-open');
            body.classList.add(`is-off-canvas-${target.dataset.transition}-${target.dataset.position}`);

            // only for our main menu off-canvas
            if (event.target.id === 'off-canvas') {
                menuButtons.forEach((menuButton) => {
                    menuButton.classList.add('is-active');
                });
            } else {
                menuButtons.forEach((menuButton) => {
                    menuButton.classList.add('is-disabled');
                });
            }
        })
        .on('close.zf.offCanvas', (event) => {
            let target = event.target;

            offCanvasActive--;

            if (offCanvasActive === 0) {
                body.classList.add('is-off-canvas-closing');
                body.classList.remove('is-off-canvas-open');
            }

            body.classList.remove(`is-off-canvas-${target.dataset.transition}-${target.dataset.position}`);

            // only for our main menu off-canvas
            if (event.target.id === 'off-canvas') {
                menuButtons.forEach((menuButton) => {
                    menuButton.classList.remove('is-active');
                });
            } else {
                menuButtons.forEach((menuButton) => {
                    menuButton.classList.remove('is-disabled');
                });
            }

            // TODO: foundation issue
            if (menuButtons.length > 0) {
                // focus the menu-button when closing the off-canvas, as the focus is not correctly passed back when
                // closing the off-canvas by hitting the close-button via keyboard
                menuButtons[0].focus();
            }
        })
        .on('closed.zf.offCanvas', () => {
            body.classList.remove('is-off-canvas-closing');
        });

    // TODO: foundation issue
    // Foundation hides everything inside the off-canvas component from screen readers, this needs to be fixed:
    inCanvasOffCanvas = toElements('.off-canvas').map((offCanvas) => {
        if (/(^|\s)in-canvas-for-(.*?)(\s|$)/i.test(offCanvas.classList.toString())) {
            let breakpoint = RegExp.$2;

            return {
                offCanvas,
                breakpoint,
            };
        }

        return false;
    });

    if (inCanvasOffCanvas.length) {
        // TODO: foundation issue
        let onResize = () => {
            inCanvasOffCanvas.forEach((item) => {
                if (Foundation.MediaQuery.atLeast(item.breakpoint)) {
                    item.offCanvas.setAttribute('aria-hidden', false);
                } else {
                    item.offCanvas.setAttribute('aria-hidden', item.offCanvas.classList.contains('is-open') !== true);
                }
            });
        };

        window.addEventListener('resize', onResize, { passive: true });
        onResize();
    }
}
