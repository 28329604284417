export function initRandomLinks() {
    const randomLinks = document.querySelectorAll('[data-random-link]');

    try {
        const randomLinkPool = randomLinks.length
            ? JSON.parse(document.getElementById('random_pages_pool').textContent)
            : [];

        if (randomLinkPool.length) {
            randomLinks.forEach((link) => {
                const randomIndex = Math.floor(Math.random() * (randomLinkPool.length - 1));

                link.href = randomLinkPool[randomIndex];
            });
        } else {
            //
        }
    } catch (e) {
        //
    }
}
