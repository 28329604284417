/**
 * Module to initialize the HlsPlayer feature if active on the page.
 */

// eslint-disable-next-line import/group-exports
export async function initializeHlsPlayer() {
    const videoDesktop = document.querySelector('[data-video-desktop]'),
        videoMobile = document.querySelector('[data-video-mobile]'),
        playPauseBtn = document.querySelector('[data-play-pause-btn]'),
        muteUnmuteBtn = document.querySelector('[data-mute-unmute-btn]');

    if (!videoDesktop && !videoMobile) {
        return;
    }

    let playerInstance = null;

    function debounce(func, wait) {
        let timeout;

        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }

    function rafThrottle(func) {
        let ticking = false;

        // eslint-disable-next-line space-before-function-paren
        return function (...args) {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    func(...args);
                    ticking = false;
                });
                ticking = true;
            }
        };
    }

    function handleButtonClick(event) {
        if (playerInstance) {
            playerInstance.handleButtonClick(event, playPauseBtn, muteUnmuteBtn);
        }
    }

    async function initPlayer() {
        try {
            const module = await import(/* webpackMode: "lazy" */ './hlsPlayer.js'),
                { HlsPlayer } = module,
                loadActiveVideo = async () => {
                    if (!playerInstance) {
                        playerInstance = new HlsPlayer(videoDesktop || videoMobile);
                    }
                    await playerInstance.loadActiveVideo(videoDesktop, videoMobile);
                    playerInstance.updatePlayPauseButton(playPauseBtn);
                    playerInstance.updateMuteUnmuteButton(muteUnmuteBtn);
                },
                handleWindowResize = rafThrottle(debounce(loadActiveVideo, 200));

            window.addEventListener('resize', handleWindowResize, { passive: true });
            document.addEventListener('click', handleButtonClick);

            // Initial load
            await loadActiveVideo();
        } catch (error) {
            console.error('Error initializing HlsPlayer:', error);
        }
    }

    await initPlayer();
}

/**
 * Initializes all video-related features on the page.
 */
// eslint-disable-next-line import/group-exports
export function initVideoFeatures() {
    initializeHlsPlayer().catch((error) => {
        console.error('Error initializing HlsPlayer:', error);
    });
}
